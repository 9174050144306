
























































import Vue from "@/translate";
import AppError from "@/components/common/AppError.vue";
import AppMain from "@/components/common/AppMain.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import ColorsMixin from "@/mixins/color-scheme";
import WorkerMixin from "@/mixins/worker";
import LanguageIcon from "@/components/LanguageIcon.vue";
import UserMenu from "@/layouts/UserMenu.vue";

export default Vue.extend(WorkerMixin)
  .extend(ColorsMixin)
  .extend({
    name: "WorkerLayout",
    components: {
      UserMenu,
      AppFooter,
      AppMain,
      AppError,
      LanguageIcon,
    },
    data() {
      return {
        auto: false,
        mini: false,
      };
    },
  });
