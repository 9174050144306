






import Vue from "@/translate";
import WorkerLayout from "@/layouts/WorkerLayout.vue";

export default Vue.extend({
  name: "Worker",
  components: {
    WorkerLayout,
  },
});
